import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardContentProps,
  CardProps,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { ReactComponent as ExtraordninaryLogo } from "../../images/logo5-black-circle.svg";
import { styled } from "@mui/material/styles";
import Links from "./Links";
import { ReactComponent as DiscordLogo } from "../../images/discord-logo-blue.svg";

const StyledCard = styled(Card)<CardProps>(() => ({
  margin: "auto",
  boxShadow: "3",
  borderRadius: "16px",
  maxWidth: "100%",
}));

const StyledCardContent = styled(CardContent)<CardContentProps>(() => ({
  padding: "5% 10%",
  "&:last-child": {
    paddingBottom: "8%",
  },
  textAlign: "justify",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "30px",
  height: "100%",
}));

export default function About() {
  return (
    <Grid
      sx={{ maxWidth: "70vw", margin: "auto", marginBottom: "42px" }}
      container
      spacing={4}
      direction={"row"}
      alignItems="stretch"
    >
      <Grid item xs={12} md={12}>
        <StyledCard>
          <CardContent>
            <Typography variant="h1" textAlign={"center"}>
              WTF is this website?
            </Typography>
          </CardContent>
        </StyledCard>
      </Grid>

      <Grid item xs={6} md={6}>
        <StyledCard style={{ height: "100%" }}>
          <StyledCardContent sx={{ justifyContent: "space-around" }}>
            <Typography variant="h2" textAlign={"center"}>
              Podcast related Discord
            </Typography>
            <ExtraordninaryLogo style={{ height: "auto", width: "85%" }} />
            <Typography variant="body1">
              If you are a podcast creator, want to be a guest or just enjoy
              listening to podcasts,{" "}
              <Link href="https://discord.gg/Pk5dPr3Uvv">
                join our community
              </Link>{" "}
              on Discord!
            </Typography>
            <Box
              component="a"
              href="https://discord.gg/Pk5dPr3Uvv"
              target="_blank"
              rel="noopener noreferrer"
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <DiscordLogo style={{ height: "auto", width: "70%" }} />
            </Box>
          </StyledCardContent>
        </StyledCard>
      </Grid>

      <Grid item xs={6} md={6} className={"small-card-outer-item"}>
        <Grid
          container
          className={"small-card-container"}
          spacing={4}
          style={{ height: "100%" }}
        >
          <Grid item xs={12} className={"small-card-inner-item"}>
            <StyledCard style={{ height: "100%" }}>
              <StyledCardContent>
                <Typography variant="h2" textAlign="center">
                  About
                </Typography>
                <Typography variant="body1">
                  This webpage is a place where I can hopefully assemble all of
                  my ever shifting hobbies in one place. The current plan is to
                  create different blog posts here, whether it's art or
                  pictures, different recipes I enjoy making or maybe even just
                  thoughts. If I ever manange to write any of them down.
                </Typography>
                <Typography variant="body1">
                  I am a woman person from Norway and I enjoy doing many
                  different things.
                </Typography>
              </StyledCardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} className={"small-card-inner-item"}>
            <StyledCard>
              <StyledCardContent>
                <Typography variant="h2" textAlign={"center"}>
                  Where to find me
                </Typography>
                <Typography variant="body1">
                  The internet is a place I enjoy hanging out at, so you can
                  also find me some other places:
                </Typography>
                <Links />
              </StyledCardContent>
            </StyledCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
