import { BlogPost } from "./Types";

import logo from "../images/notepadlogo.png";
import pizza from "../images/pizza.jpg";
import urchin from "../images/urchin.jpg";
import smoothie_banana_mint_pineapple from "../images/smoothie-banana-mint-pineapple.jpg";
import amanita_muscaria1 from "../images/mushrooms/amanita_muscaria1.jpeg";

export const blogPosts: BlogPost[] = [
  {
    id: 1,
    title: "Banana, mint and pineapple smoothie",
    type: "recipe",
    subtitle: "A yummy smoothie",
    mainImage: {
      src: smoothie_banana_mint_pineapple,
      alt: "smoothie_banana_mint_pineapple",
    },
    content: `Smoothies are versatile, nutritious beverages made by blending fruits,
      vegetables, and other ingredients like yogurt, milk, or plant-based alternatives.
      They are popular for their health benefits, offering a convenient way to consume a
      variety of vitamins, minerals, and fiber. Smoothies can be customized to suit
      individual tastes and dietary needs, making them an ideal choice for breakfast,
      a snack, or a post-workout boost. Common ingredients include berries, bananas,
      spinach, kale, and protein powders. They can also be enhanced with superfoods like
      chia seeds, flaxseeds, and spirulina. Easy to prepare and delicious, smoothies are
      a perfect addition to a healthy lifestyle.`,
    tags: [],
    images: [{ src: urchin, alt: "Sea urchin" }],
    ingredients: [
      { amount: 2, unit: "", item: "bananas" },
      { amount: 300, unit: "g", item: "frozen pineapple" },
      { amount: 300, unit: "ml", item: "yoghurt" },
      { amount: 15, unit: "", item: "mint leaves" },
      { amount: 50, unit: "g", item: "spinach" },
      { amount: 0, unit: "taste", item: "Lime or lemon juice" },
    ],
    instructions: ["Mix ingredients", "Cook on skillet"],
  },
  {
    id: 2,
    title: "Starry Night",
    subtitle: "Subtitle",
    mainImage: {
      src: urchin,
      alt: "Sea urchin",
    },
    type: "art",
    content: "A beautiful painting by Van Gogh",
    tags: [],
  },
  {
    id: 3,
    title: "My Thoughts on React",
    subtitle: "Subtitle",
    mainImage: {
      src: pizza,
      alt: "Pizza",
    },
    type: "text",
    content: "React is a powerful library for building user interfaces...",
    tags: [],
  },
  {
    id: 4,
    title: "Banana, mint and pineapple smoothie",
    type: "recipe",
    subtitle: "A yummy smoothie",
    mainImage: {
      src: logo,
      alt: "Logo",
    },
    content: `Smoothies are versatile, nutritious beverages made by blending fruits,
      vegetables, and other ingredients like yogurt, milk, or plant-based alternatives.
      They are popular for their health benefits, offering a convenient way to consume a
      variety of vitamins, minerals, and fiber. Smoothies can be customized to suit
      individual tastes and dietary needs, making them an ideal choice for breakfast,
      a snack, or a post-workout boost. Common ingredients include berries, bananas,
      spinach, kale, and protein powders. They can also be enhanced with superfoods like
      chia seeds, flaxseeds, and spirulina. Easy to prepare and delicious, smoothies are
      a perfect addition to a healthy lifestyle.`,
    tags: [],
    images: [{ src: urchin, alt: "Sea urchin" }],
    ingredients: [
      { amount: 2, unit: "pieces", item: "bananas" },
      { amount: 300, unit: "g", item: "frozen pineapple" },
      { amount: 300, unit: "ml", item: "yoghurt" },
      { amount: 15, unit: "pieces", item: "mint leaves" },
      { amount: 50, unit: "g", item: "spinach" },
      { amount: 0, unit: "to taste", item: "Lime or lemon juice" },
    ],
    instructions: ["Mix ingredients", "Cook on skillet"],
  },
  {
    id: 5,
    title:
      "This is a very long title, put here to test if wrapping properties are working",
    subtitle: "Subtitle",
    mainImage: {
      src: urchin,
      alt: "Sea urchin",
    },
    type: "art",
    content: "A beautiful painting by Van Gogh.",
    tags: [],
  },
  {
    id: 6,
    title: "My Thoughts on React",
    subtitle: "Subtitle",
    mainImage: {
      src: pizza,
      alt: "Pizza",
    },
    type: "text",
    content: "React is a powerful library for building user interfaces...",
    tags: [],
  },
  {
    id: 7,
    title: "Amanita muscaria",
    subtitle: "Subtitle",
    mainImage: {
      src: amanita_muscaria1,
      alt: "Amanita muscaria",
    },
    type: "art",
    content: "Amanita Muscaria",
    tags: ["mushrooms"],
  },
];
