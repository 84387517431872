import Card, { CardProps } from "@mui/material/Card";
import CardContent, { CardContentProps } from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Image } from "./posts/Types";
import { useLayoutEffect, useRef, useState } from "react";

interface ThumbnailProps {
  heading: string;
  body: string;
  postId: number;
  image: Image;
}

const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
  width: 370,
  height: 305,
  margin: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
  },
}));

const StyledCardContent = styled(CardContent)<CardContentProps>(() => ({
  height: "40%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

export default function Thumbnail(props: ThumbnailProps) {
  const [isOverflow, setIsOverflow] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const element = textRef.current;
    if (element && element.scrollHeight > element.clientHeight) {
      setIsOverflow(true);
    }
  }, []);

  return (
    <StyledCard>
      <CardActionArea
        sx={{ height: "100%" }}
        component={Link}
        to={`/post/${props.postId}`}
      >
        <CardMedia
          component="img"
          height="180"
          image={props.image.src}
          title={props.image.alt}
          style={{ objectFit: "cover" }}
        />
        <StyledCardContent>
          <Box
            sx={{
              maxHeight: isOverflow ? "82%" : "auto",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              textOverflow: "ellipsis",
            }}
          >
            <Typography
              component="div"
              gutterBottom
              variant="h5"
              ref={textRef}
              sx={{
                whiteSpace: "normal",
              }}
            >
              {props.heading}
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" noWrap>
            {props.body}
          </Typography>
        </StyledCardContent>
      </CardActionArea>
    </StyledCard>
  );
}
