import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import { StyledContainer } from "../StyledContainer";

export default function ErrorPage() {
  const error = useRouteError();

  let errorMessage: string;

  if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = `${error.status} ${
      error.error?.message || error.statusText
    }`;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = "Unknown error";
  }

  return (
    <StyledContainer>
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{errorMessage}</i>
        </p>
        <p>
          I might also be working on a better error page, but don't hold your
          breath.
        </p>
      </div>
    </StyledContainer>
  );
}
