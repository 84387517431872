import React from "react";
import { RecipePost } from "../../Types";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import RecipeCalculator from "./RecipeCalculator";

export default function RecipePostComponent(props: RecipePost) {
  return (
    <Card sx={{ maxWidth: 800, margin: "auto", marginTop: 4, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h4" component="div" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="h5" color="text.secondary">
          {props.subtitle}
        </Typography>
      </CardContent>
      <CardMedia
        component="img"
        image={props.mainImage.src}
        alt={props.mainImage.alt}
        sx={{ borderBottom: "1px solid #ccc" }}
      />
      <CardContent>
        <Typography variant="body1" paragraph>
          {props.content}
        </Typography>
        <RecipeCalculator ingredients={props.ingredients} />
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" gutterBottom>
          Instructions
        </Typography>
        <List>
          {props.instructions.map((instruction, index) => (
            <ListItem key={index}>
              <ListItemText primary={instruction} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
