import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Recipe from "../posts/components/Recipe/Recipe";
import { blogtheme } from "../Themes";
import { blogPosts } from "../posts/Posts";
import { BlogPost } from "../posts/Types";
import { useParams } from "react-router-dom";
import Art from "../posts/components/Art";
import Text from "../posts/components/Text";

export default function Post() {
  const { postId } = useParams();

  const post = blogPosts.find((post) => post.id.toString() === postId);

  const postComponent = (post: BlogPost | undefined) => {
    if (post) {
      switch (post.type) {
        case "recipe":
          return <Recipe {...post} />;
        case "art":
          return <Art {...post} />;
        default:
          return <Text {...post} />;
      }
    } else {
      /* eslint-disable no-throw-literal */
      throw {
        data: "",
        internal: false,
        statusText: `No routes matched location "${postId}"`,
        status: 404,
      };
      /* eslint-enable no-throw-literal */
    }
  };

  return (
    <ThemeProvider theme={blogtheme}>
      <CssBaseline />
      {postComponent(post!)}
    </ThemeProvider>
  );
}
