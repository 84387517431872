import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Container,
  ButtonGroup,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Ingredient } from "../../Types";
import { convertIngredientToImperial, roundTwoDecimalPlaces } from "./utils";

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blueGrey[50]),
  backgroundColor: blueGrey[50],
  borderColor: blueGrey[200],
  "&:hover": {
    backgroundColor: blueGrey[100],
    borderColor: blueGrey[300],
  },
}));

const StyledInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 0,
      borderColor: blueGrey[200],
    },
    "&:hover fieldset": {
      borderColor: blueGrey[300],
    },
    "&.Mui-focused fieldset": {
      borderColor: blueGrey[500],
    },
    "& input": {
      textAlign: "center",
      width: 60,
    },
  },
});

interface CalculatorProps {
  ingredients: Ingredient[];
}

const defaultPortions = 4;

export default function RecipeCalculator(props: CalculatorProps) {
  const [count, setCount] = useState(defaultPortions);
  const [ingredients, setIngredients] = useState(props.ingredients);
  const [unit, setUnit] = useState("metric");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIngredients(calculateIngredients(count, event.target.value));
    setUnit(event.target.value);
  };

  const calculateIngredients = (nextCount: number, unit: string) =>
    props.ingredients.map((ingredient) => {
      const onePortion = ingredient.amount / defaultPortions;
      const xPortions = onePortion * nextCount;
      if (unit === "imperial") {
        return convertIngredientToImperial({
          ...ingredient,
          amount: xPortions,
        });
      }
      return { ...ingredient, amount: roundTwoDecimalPlaces(xPortions) };
    });

  return (
    <Card sx={{ maxWidth: 800, margin: "auto", marginTop: 4, boxShadow: 3 }}>
      <Container>
        <ButtonGroup>
          <StyledButton
            onClick={() => {
              setIngredients(calculateIngredients(count - 1, unit));
              setCount((prev) => prev - 1);
            }}
            disabled={count === 1}
          >
            <RemoveIcon fontSize="small" />
          </StyledButton>
          <StyledInput size="small" value={count} />
          <StyledButton
            onClick={() => {
              setIngredients(calculateIngredients(count + 1, unit));
              setCount((prev) => prev + 1);
            }}
          >
            <AddIcon fontSize="small" />
          </StyledButton>
        </ButtonGroup>
      </Container>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={unit}
        onChange={handleChange}
      >
        <FormControlLabel value="metric" control={<Radio />} label="Metric" />
        <FormControlLabel
          value="imperial"
          control={<Radio />}
          label="Imperial"
        />
      </RadioGroup>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Ingredients
        </Typography>
        <List>
          {ingredients.map((ingredient, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={
                  ingredient.unit === "taste"
                    ? `${ingredient.item} to taste`
                    : `${ingredient.amount} ${ingredient.unit} ${ingredient.item}`
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
