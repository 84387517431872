import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import ErrorPage from "./routes/ErrorPage";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "./Themes";
import { CssBaseline } from "@mui/material";
import MenuBar from "./MenuBar";
import Post from "./routes/Post";
import RandomPost from "./routes/RandomPost";
import SeaUrchins from "./routes/SeaUrchins";
import About from "./routes/About/About";

const HeaderLayout = () => (
  <>
    <header>
      <MenuBar />
    </header>
    <Outlet />
  </>
);

const router = createBrowserRouter([
  {
    element: <HeaderLayout />,
    children: [
      { path: "/", element: <App posts="all" /> },
      { path: "/post/:postId", element: <Post /> },
      { path: "/random", element: <RandomPost /> },
      { path: "/sea-urchins", element: <SeaUrchins /> },
      { path: "/about", element: <About /> },
      { path: "/mushrooms", element: <App posts="mushrooms" /> },
    ],
    errorElement: (
      <>
        <header>
          <MenuBar />
        </header>
        <ErrorPage />
      </>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
