import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Grid,
} from "@mui/material";
import GitHubLogo from "../../images/github-mark-white.svg";
import YouTubeLogo from "../../images/yt_icon_rgb.png";
import TwitterLogo from "../../images/x.svg";
import TwitchLogo from "../../images/Twitch_logo.svg";

const socialMediaLinks = [
  {
    name: "YouTube",
    url: "https://www.youtube.com/channel/@camilla_films_stuff",
    image: YouTubeLogo,
  },
  {
    name: "Podcast",
    url: "https://www.youtube.com/channel/@camilla_talks_to_people",
    image: YouTubeLogo,
  },
  {
    name: "Twitch",
    url: "https://www.twitch.tv/girlscantrap",
    image: TwitchLogo,
  },
];

const bottomLinks = [
  {
    name: "GitHub",
    url: "https://github.com/fishyfrogface",
    image: GitHubLogo,
  },
  {
    name: "Twitter",
    url: "https://twitter.com/remotelol",
    image: TwitterLogo,
  },
];

const SocialMediaLinks = () => {
  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      spacing={3}
    >
      <Grid item>
        <List sx={{ marginRight: "15vw" }}>
          {socialMediaLinks.map((link) => (
            <ListItem
              component="a"
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              key={link.url}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{ width: 60, height: 60 }}
                  slotProps={{ img: { sx: { objectFit: "contain" } } }}
                  variant="square"
                  src={link.image}
                  alt={link.name}
                />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  variant: "h5",
                  fontWeight: "800",
                  color: "white",
                }}
                primary={link.name}
                sx={{ marginLeft: "10px" }}
              />
            </ListItem>
          ))}
        </List>
      </Grid>

      <Grid
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        item
      >
        <Grid container spacing={4}>
          {bottomLinks.map((link) => (
            <Grid
              component="a"
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              key={link.url}
              item
            >
              <Avatar
                sx={{ width: 60, height: 60 }}
                slotProps={{ img: { sx: { objectFit: "contain" } } }}
                variant="square"
                src={link.image}
                alt={link.name}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SocialMediaLinks;
