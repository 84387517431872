import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

export default function SeaUrchins() {
  return (
    <Card sx={{ maxWidth: 800, margin: "auto", marginTop: 4, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          Sea Urchins
        </Typography>
        <Typography variant="body1">
          This page is going to show you all sea urchins I've drawn. It's not
          currently implemented.
        </Typography>
      </CardContent>
    </Card>
  );
}
