import createTheme from "@mui/material/styles/createTheme";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: { paper: "#161b22", default: "#010409" },
  },
});

export const healthyGamer = createTheme({
  palette: {
    mode: "dark",
    background: { paper: "#00C63F", default: "#0B1F33" },
  },
  typography: {
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
    h4: {
      fontSize: "1.4rem",
    },
    h5: { fontSize: "1.2rem" },
  },
});

export const blogtheme = createTheme({
  palette: {
    mode: "dark",
    background: { paper: "#161b22", default: "#010409" },
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    h4: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 500,
    },
    body1: {
      fontSize: '1.1rem',
    },
  },
});
