import React from "react";
import { ArtPost } from "../Types";
import { Card, CardContent, Typography, CardMedia } from "@mui/material";

export default function ArtPostComponent(props: ArtPost) {
  return (
    <Card sx={{ maxWidth: 800, margin: "auto", marginTop: 4, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h4" component="div" gutterBottom>
          {props.title}
        </Typography>
      </CardContent>
      <CardMedia
        component="img"
        image={props.mainImage.src}
        alt={props.mainImage.alt}
        sx={{ borderBottom: "1px solid #ccc" }}
      />
      <CardContent>
        <Typography variant="h5" color="text.secondary">
          {props.subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
}
