import Thumbnail from "./Thumbnail";
import { StyledContainer } from "./StyledContainer";
import { Grid } from "@mui/material";
import { blogPosts } from "./posts/Posts";
import { PostSet } from "./posts/Types";

interface AppProps {
  posts: PostSet;
}

function App(props: AppProps) {
  const currentPostSet = () => {
    switch (props.posts) {
      case "mushrooms":
        return blogPosts.filter((post) => post.tags.includes("mushrooms"));
      default:
        return blogPosts;
    }
  };

  return (
    <StyledContainer className="styled-container">
      <Grid container spacing={2} justifyContent="center">
        {currentPostSet().map((post) => (
          <Grid key={post.id} item>
            <Thumbnail
              heading={post.title}
              body={post.subtitle}
              postId={post.id}
              image={post.mainImage}
            />
          </Grid>
        ))}
      </Grid>
    </StyledContainer>
  );
}

export default App;
