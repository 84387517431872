import { Container, ContainerProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledContainer = styled(Container)<ContainerProps>(
  ({ theme }) => ({
    marginTop: 50,
    marginBottom: 50,
    [theme.breakpoints.up("md")]: {
      maxWidth: "90vw",
    },
  })
);

export const CenteredContainer = styled(Container)<ContainerProps>(() => ({
  marginTop: 50,
  marginBottom: 50,
  maxWidth: "200px",
}));
